import request from '@/utils/requestDoctor';

const baseUrl = 'https://scusapi.shanci.tech';

function pageQueryResource(query) {
  return request({
    url: baseUrl + '/doctor-reception/health-consultation/pageQueryResource',
    method: 'post',
    data: query,
  });
}

function orderConfirmation(query) {
  return request({
    url: baseUrl + '/doctor-reception/health-consultation/confirmation',
    method: 'post',
    data: query,
  });
}

export { pageQueryResource, orderConfirmation };
