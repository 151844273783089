<template>
  <div class="cp-out">
    <div class="cp-top">
      <el-form :inline="true" ref="searchFormRef" :model="searchState">
        <div>
          <el-form-item label="咨询时间" prop="date">
            <!-- <el-config-provider :locale="zhCn"> -->
            <el-date-picker
              v-model="searchState.date"
              type="datetimerange"
              range-separator="至"
              start-placeholder="开始时间"
              end-placeholder="结束时间"
              value-format="YYYY-MM-DD HH:mm:ss"
            >
            </el-date-picker>
            <!-- </el-config-provider> -->
          </el-form-item>
          <el-form-item label="患者姓名" prop="patientName">
            <el-input
              v-model="searchState.patientName"
              placeholder="请输入患者姓名"
            />
          </el-form-item>
          <el-form-item label="订单号" prop="resourceNo">
            <el-input
              v-model="searchState.resourceNo"
              placeholder="请输入订单号"
            />
          </el-form-item>
        </div>

        <el-form-item label="咨询问题" prop="consultationContent">
          <el-input
            v-model="searchState.consultationContent"
            placeholder="请输入咨询问题"
          />
        </el-form-item>

        <el-form-item label="手机号" prop="patientMobile">
          <el-input
            v-model="searchState.patientMobile"
            placeholder="请输入手机号"
          />
        </el-form-item>

        <el-form-item>
          <el-button type="primary" @click="queryData()">查询</el-button>
          <el-button @click="resetForm(searchFormRef)">重置</el-button>
        </el-form-item>
      </el-form>
    </div>

    <div class="cp-table">
      <el-table :data="pageState.tableData" style="width: 100%" border>
        <el-table-column fixed prop="index" label="序号" width="60" />
        <el-table-column fixed prop="resourceNo" label="订单号" width="200" />
        <el-table-column prop="patientName" label="患者姓名" width="120" />
        <el-table-column prop="patientMobile" label="手机号" width="150" />
        <el-table-column label="咨询问题" :show-overflow-tooltip="true">
          <template #default="scope">
            <div class="consultation-content consultation-content-qes">
              {{ scope.row.consultationContent }}
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="consultationTime" label="咨询时间" width="200" />

        <el-table-column fixed="right" label="操作" width="200">
          <template #default="scope">
            <el-button type="text" size="small" @click="handleDetail(scope.row)"
              >详情</el-button
            >
            <el-button
              v-if="!scope.row.confirmStatus"
              type="text"
              size="small"
              @click="handleGet(scope.row)"
              >抢单</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="cp-pn">
      <el-pagination
        background
        layout="prev, pager, next"
        :total="page.total"
        v-model:currentPage="page.pageIndex"
        @current-change="handleCurrentChange"
      >
      </el-pagination>
    </div>

    <el-dialog
      :visible.sync="pageState.showDetail"
      title="详情"
      width="50%"
      center
    >
      <el-descriptions title="订单详情">
        <DescriptionItem label="患者姓名：">{{
          pageState.detailInfo.patientName
        }}</DescriptionItem>
        <DescriptionItem label="健康咨询单号：">{{
          pageState.detailInfo.resourceNo
        }}</DescriptionItem>
        <DescriptionItem label="手机号：">{{
          pageState.detailInfo.patientMobile
        }}</DescriptionItem>
        <DescriptionItem label="咨询时间：">
          {{ pageState.detailInfo.consultationTime }}
        </DescriptionItem>
        <DescriptionItem label="性别：">{{
          pageState.detailInfo.patientSex == '2' ? '女' : '男'
        }}</DescriptionItem>
        <DescriptionItem label="支付费用：">{{
          pageState.detailInfo.payFee
        }}</DescriptionItem>
        <DescriptionItem label="年龄：">{{
          pageState.detailInfo.patientAge
        }}</DescriptionItem>
        <DescriptionItem label="确认状态：">{{
          pageState.detailInfo.confirmStatus ? '已确认' : '未确认'
        }}</DescriptionItem>
      </el-descriptions>
      <el-divider></el-divider>
      <el-descriptions title="咨询内容：">
        <DescriptionItem label="">
          {{ pageState.detailInfo.consultationContent }}</DescriptionItem
        >
      </el-descriptions>

      <div v-if="pageState.detailInfo.consultationImageUrl">
        <el-image
          v-for="(
            item, index
          ) in pageState.detailInfo.consultationImageUrl.split(',')"
          :key="item"
          style="width: 100px; height: 100px; margin-right: 10px"
          :preview-src-list="
            pageState.detailInfo.consultationImageUrl.split(',')
          "
          :initial-index="index"
          :src="item"
        ></el-image>
      </div>

      <template #footer>
        <span class="dialog-footer">
          <el-button
            v-if="!pageState.detailInfo.confirmStatus"
            type="primary"
            @click="handleGet(pageState.detailInfo)"
            >抢单</el-button
          >
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script >
import { reactive, ref, onMounted } from '@vue/composition-api';
import DescriptionItem from '@/components/DescriptionItem';
import { pageQueryResource, orderConfirmation } from '@/api/doctorManage/consultingPool';

// import { ElMessage } from 'element-plus';
//表单
const __sfc_main = {};
__sfc_main.setup = (__props, __ctx) => {
  const searchFormRef = ref('');
  const searchState = reactive({
    date: [],
    patientName: '',
    resourceNo: '',
    consultationContent: '',
    confirmStatus: false,
    patientMobile: ''
  });

  //分页
  const page = reactive({
    pageIndex: 1,
    pageSize: 10,
    total: 0
  });
  const handleCurrentChange = () => {
    queryData();
  };

  //表格
  const resetForm = formEl => {
    if (!formEl) return;
    formEl.resetFields();
    page.pageIndex = 1;
    queryData();
  };
  const pageState = reactive({
    tableData: [],
    detailInfo: {},
    showDetail: false
  });
  const queryData = async () => {
    const {
      pageIndex
    } = page;
    const {
      patientName,
      resourceNo,
      consultationContent,
      confirmStatus,
      patientMobile,
      date
    } = searchState;
    const res = await pageQueryResource({
      isCancel: false,
      consultationStartTime: date[0],
      consultationEndTime: date[1],
      patientName,
      resourceNo,
      consultationContent,
      confirmStatus,
      patientMobile,
      page: {
        pageIndex,
        pageSize: 10
      }
    });
    pageState.tableData = res.data.data;
    pageState.tableData.forEach((item, index) => {
      item.index = index + 1;
    });
    page.total = Number(res.data.total);
  };
  onMounted(async () => {
    queryData();
  });

  //查看详情
  const handleDetail = row => {
    console.log(row, 8988);
    pageState.showDetail = true;
    pageState.detailInfo = row;
  };
  const handleGet = async row => {
    const res = await orderConfirmation({
      resourceNo: row.resourceNo
    });
    if (res.code == 200) {
      // ElMessage({
      //   message: '抢单成功',
      //   type: 'success',
      // });

      queryData();
    }
    pageState.showDetail = false;
  };
  return {
    searchFormRef,
    searchState,
    page,
    handleCurrentChange,
    resetForm,
    pageState,
    queryData,
    handleDetail,
    handleGet
  };
};
__sfc_main.components = Object.assign({
  DescriptionItem
}, __sfc_main.components);
export default __sfc_main;
</script>
<style lang="scss" scoped>
.cp-out {
  padding: 20px;
  .cp-top {
    padding-bottom: 20px;
  }
  .cp-table {
    padding-bottom: 20px;
    min-height: 300px;
  }
}
.consultation-content {
  cursor: pointer;
  &.consultation-content-qes {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    word-break: break-all;
  }
}
</style>
